.navbar {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  height: rem(800px, 100%);
  width: rem(300px, 100%);
  padding: var(--mantine-spacing-md);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: rem(1px, 100%) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.header {
  width: 100%;
  padding: 16px 0;
  display: block;
  text-align: center;

  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  border-bottom: rem(1px, 100%) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.links {
  flex: 1;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);

}

.linksInner {
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
  gap: 4px;
  display: flex;
  flex-flow: column;
}

.footer {
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  border-top: rem(1px, 100%) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  display: inline-block;
  width: 36px;
  height: 36px;
}

.logoImg {
  width: 48px;
  height: 48px;
  display: inline-block;
}

.logout {
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    color: #0000cc;
  };
}