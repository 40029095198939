.control {
  font-weight: 500;
  display: flex;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
}

.group {
  gap: 0;
  justify-content: space-around;
  align-content: space-around;
  display: flex;
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  display: flex;
  margin-left: 48px;
  &:hover {
    color: #0000cc;
  };
}

.chevron {
  transition: transform 200ms ease;
}
